import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux/store';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import 'config/webfont';
import 'styles/styles.scss';
import ErrorBoundaryContainer from './components/modules/ErrorBoundary/ErrorBoundaryContainer';

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundaryContainer>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        </ErrorBoundaryContainer>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
