import React from 'react';
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import './button.scss';
import SVGIcon from '../SVGIcon';
import { colors } from '~/styles';
import useHref from '~/hooks/useHref';

export type ButtonVariant =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'icon-only'
    | 'danger'
    | 'link-light'
    | 'secondary-form'
    | 'base';

export type ButtonProps = BootstrapButtonProps & {
    variant?: ButtonVariant;
    label?: string;
    className?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    iconLeft?: any;
    iconRight?: any;
    isProcessing?: boolean;
    isShowLabel?: boolean;
    isLeftChildren?: boolean;
};

export default function Button({
    variant = 'primary',
    size = 'sm',
    label,
    className = '',
    onClick,
    iconLeft: IconLeft,
    iconRight: IconRight,
    children,
    isProcessing,
    isShowLabel,
    isLeftChildren,
    ...rest
}: ButtonProps): JSX.Element {
    const { isRoyalAnglianConnect, isOUBC } = useHref();

    const onGetTextColor = () => {
        if (isRoyalAnglianConnect) {
            return colors['red-500'];
        }

        if (isOUBC) {
            return colors['rowing-blue-1000'];
        }

        if (rest.disabled) {
            switch (variant) {
                case 'primary':
                    return colors['davy-grey-400'];
                case 'secondary':
                    return colors['stone-200'];
                case 'tertiary':
                case 'link':
                case 'icon-only':
                    return colors['stone-300'];
                case 'danger':
                    return colors['neutral-0'];
            }
        }

        switch (variant) {
            case 'primary':
                return colors['neutral-0'];
            case 'secondary':
            case 'tertiary':
            case 'link':
            case 'icon-only':
                return colors.warning;
        }
    };

    return (
        <BootstrapButton
            variant={''}
            className={`button btn-${size} btn-${variant} ${className}`}
            onClick={onClick}
            {...rest}>
            <div className='content'>
                {!!isProcessing && (
                    <Spinner
                        animation='border'
                        role='status'
                        className='button__spinner visible'
                        size='sm' />
                )}

                <div
                    className={`content ${isProcessing && !isShowLabel ? 'invisible' : 'visible'
                        }`}>
                    {IconLeft && (
                        <SVGIcon
                            Icon={IconLeft}
                            color={onGetTextColor()}
                            size={size === 'sm' ? 20 : 24} />
                    )}
                    {isLeftChildren && children}
                    {label}
                    {!isLeftChildren && children}
                    {IconRight && (
                        <SVGIcon
                            Icon={IconRight}
                            color={onGetTextColor()}
                            size={size === 'sm' ? 20 : 24} />
                    )}
                </div>
            </div>
        </BootstrapButton>
    );
}
