/* eslint-disable space-before-function-paren */
/* eslint-disable no-cond-assign */
/* eslint-disable camelcase */
import { isArray, isEmpty, isUndefined } from 'lodash';
import { Chat, ChatType, ChoiceData, EventPillType, EventQuestionType, FeelingStatus, File, Message, ModerateMembers, Region, SelectedLocation } from '~/types';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { colors } from '~/styles';
import crypto from 'crypto-js';
import { webView, API_CONFIG } from '~/config/constants';
import { decode } from 'html-entities';
import branding from 'branding.json';

export const capitalize = (str: string): string => {
    return str.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    ).replace('Of', 'of').replace('And', 'and');
};

export const camelToReadableText = (text: string): string =>
    capitalize(text.replace(/([A-Z])/g, ' $1'));

export const isValidEmail = (str: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        str
    );

export const onGenerateChoiceList = (data: Array<string>): Array<ChoiceData> => {
    return data.map((v, i) => ({ id: i, title: v, value: v }));
};

export const onFormatNumbers = (value: number): string => {
    if (!value) return '0';

    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const onFilterList = (arr: any[], value: any): any[] => {
    if (arr.includes(value)) return arr.filter(arrValue => arrValue !== value);

    return arr.concat(value);
};

export const isValidPassword = (value: string): boolean => {
    return value?.trim()?.length >= 6;

    // return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}/.test(value); // Min 8, 1 upper, 1 lower, 1 numeric, 1 Special character
};

export const onGenerateYears = () => {
    const years = [];

    for (let i = 1900; i <= onGetCurrentYear(); i++) {
        years.push(`${i}`);
    }

    return years.reverse();
};

export const onGetCurrentYear = () => {
    return new Date().getFullYear();
};

export const hasErrorMessage = (errorMessage: Object): boolean => {
    return !!Object.values(errorMessage).filter(message => message).length;
};

export const hasEmptyList = (...args: any[]): boolean => {
    return !args.filter(value => isEmpty(value)).length;
};

export const isValidString = (str: any): boolean => {
    if (typeof str !== 'string') return !isEmpty(str);

    return !!str.trim().length;
};

export const isLocalImage = (uri?: string) => {
    return !uri?.startsWith('http');
};

export const onGetInitials = (defaultStr: string, firstName?: string, lastName?: string): string => {
    if (!defaultStr) return '';

    if (lastName && firstName) return `${firstName[0]}${lastName[0]}`;

    if (firstName && !lastName) return firstName[0];

    if (!firstName && lastName) return lastName[0];

    return defaultStr[0];
};

export const jwtDecode = (token: string): any => jwt_decode(token);

export const isTokenExpired = (time: any) => new Date().getTime() >= time;

export const onGetFeelingStatus = (value: number): FeelingStatus => {
    if (value >= 0 && value <= 20) {
        return 'Depressed';
    } else if (value > 20 && value <= 40) {
        return 'Sad';
    } else if (value > 40 && value <= 60) {
        return 'Fine';
    } else if (value > 60 && value <= 80) {
        return 'Happy';
    }

    return 'Ecstatic';
};

export const onGreetings = () => {
    const currentHour = +moment().format('HH');

    if (currentHour >= 3 && currentHour < 12) {
        return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
        return 'Good Afternoon';
    }

    return 'Good Evening';
};

export const onFormatToday = (format: string = 'DD MMMM, YYYY') => {
    return moment(new Date()).format(format);
};

export const onGetParamsFromURL = (url: string, param: string) => {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params: any = {};
    let match;

    while (match = regex.exec(url)) {
        params[match[1]] = match[2];
    }

    return params[param];
};

export const textToUpperUnderscore = (str: string | any) => {
    return str.split(' ').join('_').toUpperCase();
};

export const onStripStr = (str: string) => {
    if (!str) return '';

    if (str.length > 150) {
        return `${str.substring(0, 150)}...`;
    }

    return str;
};

export const removeAllNewLine = (str: string) => {
    if (!str) return '';

    return str.replace(/\r?\n|\r/g, '');
};

export const onConvertVisibilities = (str: any): any => {
    if (!str) {
        return 'My Group';
    }

    return str.replace('_', ' ').toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const showError = (message: string, time?: number) => {
    console.log('Error:', message);

    setTimeout(() => {
        throw new Error(message);
    }, time || 100);
};

export const onFormatMyConnections = (connections: any[], connected?: boolean, requested?: boolean) => {
    if (!connections) return [];

    return connections.map(connection => {
        const { request } = connection;

        return {
            ...onFormatMember(connection),
            connected: connected === undefined ? true : connected,
            requested: request || requested
        };
    }).filter(connection => connection.firstName);
};

export const onFormatMember = (member: any) => {
    const { user_id, first_name, last_name, image_url, email } = member;

    return {
        id: user_id,
        firstName: first_name,
        lastName: last_name,
        image: image_url,
        email
    };
};

export const onFormatModerateMembers = (members: any[], type: ModerateMembers) => {
    if (!members) return [];

    return members?.map(member => {
        const { admin, group_admin, role, event_admin, voice_news_creator, crew_admin } = member;

        return {
            ...onFormatMember(member),
            ...(type === 'MEMBERS'
                ? { admin: admin, groupAdmin: group_admin, event_admin, voice_news_creator, crew_admin }
                : {}),
            ...(type === 'CREATORS_PUBLISHERS' ? { role } : {})
        };
    }).filter(member => member.firstName);
};

export const onFormatInviteConnections = (connections: any[]) => {
    if (!connections) return [];

    return connections
        .map((connection) => {
            const {
                user_id,
                first_name,
                last_name,
                image_url
            } = connection;

            return {
                id: user_id,
                firstName: first_name,
                lastName: last_name,
                image: image_url,
                isLoading: false
            };
        })
        .filter((connection) => connection.firstName);
};

export const objectToParams = (values: Object, isAsIsValue?: boolean) => {
    return Object.entries(values)
        .map(
            ([key, val]) =>
                `${key}=${typeof val === 'string'
                    ? encodeURI(val || '')?.replace(/&/g, 'andkey')
                    : typeof val === 'number' && isAsIsValue
                        ? val
                        : val || ''
                }`
        )
        .join('&');
};

export const isEqualObjects = (obj1: Object, obj2: Object) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const objectValuesCount = (obj: Object) => {
    return Object.values(obj).filter(val => val !== undefined && val !== '' && val !== 0).length;
};

export const onHasValueInnerObjects = (obj: Object) => {
    return !!Object.values(obj).filter(v => {
        if (typeof v === 'object' && !isArray(v) && v) {
            return Object.values(v).filter(vv => vv !== undefined && vv !== '').length;
        }

        if (isArray(v) || typeof v === 'string') return v.length;

        return false;
    }).length;
};

export const onGetAspectRatio = (type: 'feature_image') => {
    switch (type) {
        case ('feature_image'):
            return '440 / 294';
    }
};

export const onGetImg = (img: string, currentImg: string) => {
    if (!img) return currentImg;

    if (!currentImg) return img;

    const expiration = onGetParamsFromURL(currentImg, 'Expires');

    if (img && currentImg) {
        try {
            if (img?.split('?')[0] !== currentImg?.split('?')[0]) {
                return img;
            }
        } catch (error) {
            console.log(error);
        }
    }

    if (!expiration) return img;

    if (isTokenExpired(+expiration * 1000)) return img;

    return currentImg;
};

export const onFormatNotificationGroupApproval = (approvals: any): any[] => {
    const { connection_group_approval, connection_group_removal } = approvals;
    let groupNotification: any[] = [];

    if (connection_group_approval) {
        const approvals = connection_group_approval?.map((val: any) => {
            const { date_joined, group_details: { group_id, group_name, group_image_url } } = val;

            return {
                type: 'JOIN_GROUP_ACCEPTED',
                date: onFormatNotificationDate(date_joined),
                group_info: {
                    id: group_id,
                    name: group_name,
                    image: group_image_url
                },
                status: 'SEEN'
            };
        });

        groupNotification = [...groupNotification, ...approvals];
    }

    if (connection_group_removal) {
        const removals = connection_group_removal?.map((val: any) => {
            const { date_removed, group_details: { group_id, group_name, image_url } } = val;

            return {
                type: 'REMOVE_GROUP_MEMBER',
                date: onFormatNotificationDate(date_removed),
                group_info: {
                    id: group_id,
                    name: group_name,
                    image: image_url
                },
                status: 'SEEN'
            };
        });

        groupNotification = [...groupNotification, ...removals];
    }

    return groupNotification;
};

export const onFormatNotificationMemberApproval = (approvals: any[]): any[] => {
    return approvals?.map(val => {
        const { date_accepted, connection_details: { user_id, first_name, last_name, image_url } } = val;

        return {
            type: 'CONNECTION_ACCEPTED',
            date: onFormatNotificationDate(date_accepted),
            user_info: {
                id: user_id,
                firstName: first_name,
                lastName: last_name,
                image: image_url
            },
            status: 'SEEN'
        };
    });
};

export const onFormatNotificationDate = (date: string) => {
    return moment(date).format('DD MMMM YYYY hh:mm A');
};

export const onGetErrorMessage = (message: string) => {
    const regex = /<(.*)>/g;
    const matches = regex.exec(message);

    let data: { message: string, email?: string } = {
        message: message?.replace(regex, '')
    };

    if (matches?.length) data = { ...data, email: matches[1] };

    return data;
};

export const onFormatEventDate = (date: string, format = 'ddd, DD MMMM YYYY') => {
    return convertUTCToLocalTime(date, format);
};

export const onGetFeelingStatusColors = (status: FeelingStatus) => {
    switch (status) {
        case 'Depressed':
            return {
                bg: colors.verySadBg,
                stroke: colors.verySad
            };
        case 'Sad':
            return {
                bg: colors.sadBg,
                stroke: colors.sad
            };
        case 'Fine':
            return {
                bg: colors.fineBg,
                stroke: colors.fine
            };
        case 'Happy':
            return {
                bg: colors.happyBg,
                stroke: colors.happy
            };
        case 'Ecstatic':
            return {
                bg: colors.veryHappyBg,
                stroke: colors.veryHappy
            };
        default:
            return {
                bg: colors.fineBg,
                stroke: colors.fine
            };
    }
};

export const onGetCommaFromList = (list: any[], index: number) => {
    return list?.length - 1 !== index ? ', ' : '';
};

export const onFormatProfile = (data: any, profile?: any) => {
    const { first_name, last_name, industry, service_start_date, service_end_date, address, image_url, phone_number, group_id, group_logo_url, group_name, work_experience, story, connections, coordinates, is_group_or_subgroup_admin, service, family_group_id, family_group, hide_contact, college, subject } = data;

    let storyContent = '';

    if (story?.length && story[0]?.my_story?.length) {
        storyContent = story[0]?.my_story[0];
    }

    return {
        firstName: first_name,
        lastName: last_name,
        industries: industry,
        yearOfService: {
            from: service_start_date,
            to: service_end_date
        },
        location: address,
        photo: image_url,
        phoneNumbers: phone_number,
        group: {
            id: group_id,
            image: group_logo_url,
            name: group_name
        },
        work_experience,
        story: storyContent,
        connections: onFormatMyConnections(connections, true),
        coordinates,
        isGroupOrSubGroupAdmin: is_group_or_subgroup_admin,
        service,
        family_group_id,
        family_group,
        hideContact: hide_contact,
        college,
        subject
    };
};

export const onFormatPushNotifications = (groupPushNotifications: any): any[] => {
    let groupNotification: any[] = [];

    const { group_notification, subgroup_notification } = groupPushNotifications;

    const notifications = [...(isArray(group_notification) ? group_notification : []), ...(isArray(subgroup_notification) ? subgroup_notification : [])];

    if (notifications) {
        const notifs = notifications?.map((val: any) => {
            return onFormatPushNotification(val);
        });

        groupNotification = [...groupNotification, ...notifs];
    }

    return groupNotification;
};

export const onFormatPushNotification = (data: any) => {
    const { body, html_body, date_created, group_id, group_image_url, group_name, screen, title, type_id, type_image, url, author, author_image, published_date, type, member_id, member_name, article_type, is_date_changed, is_cancelled } = data;

    let notificationType = '';

    if (type_image && screen?.toLowerCase() === 'events') {
        if (is_cancelled) {
            notificationType = 'GROUP_CANCELLED_EVENT';
        } else if (is_date_changed) {
            notificationType = 'GROUP_UPDATED_DATE_EVENT';
        } else {
            notificationType = 'GROUP_CREATED_EVENT';
        }
    } else if (type === 'Membership Request') {
        notificationType = 'NEW_REQUESTED_MEMBER';
    } else {
        notificationType = 'ARTICLE';
    }

    return {
        _id: type_id,
        title,
        body,
        screen,
        type: notificationType,
        date: onFormatNotificationDate(date_created),
        date_created,
        image: type_image || group_image_url,
        groupImage: group_image_url,
        group_info: {
            id: group_id,
            name: group_name
        },
        event_info: {
            id: type_id,
            image: type_image || group_image_url,
            name: title
        },
        isAnnouncement: !type_id || !screen,
        status: 'SEEN',
        url,
        html_body,
        author,
        author_image,
        published_date,
        member_id,
        member_name,
        article_type
    };
};

export const isValidUrl = (url: string) => {
    return /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi.test(url);
    // return /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/.test(url);
};

export const scriptHeightUpdate = `
const resizeObserver = new ResizeObserver(entries => {
    window.ReactNativeWebView.postMessage(document.body.scrollHeight);
});

resizeObserver.observe(document.body);
`;

export const scriptHeightArticle = `
setTimeout(function() {
    window.ReactNativeWebView.postMessage(document.documentElement.scrollHeight);
  }, 500);
  true;
`;

export const scriptImageScale = `
 document.querySelectorAll('img').forEach(el=>{
    el.style.maxWidth='100%';
 });
`;

export const getContentsFromHTMLBody = (content: string, isRemoveElement?: boolean) => {
    if (!content) return '';

    if (isRemoveElement) {
        return removeHTMLElements(content.replace(/^.*?<body>(.*?)<\/body>.*?$/s, '$1'));
    }

    return content.replace(/^.*?<body>(.*?)<\/body>.*?$/s, '$1');
};

export const removeHTMLElements = (content: string) => {
    if (!content) return '';

    return content.replace(/(<([^>]+)>)/gi, ' ').replace(/ +(?= )/g, '').trim();
};

export const sortByName = (data: any[], field?: string) => {
    return data.sort((a, b) => {
        if (field) {
            return a[field]?.trim()?.localeCompare(b[field]?.trim());
        }

        const result = a?.firstName?.trim()?.localeCompare(b?.firstName?.trim()) || a?.first_name?.trim()?.localeCompare(b?.first_name?.trim());

        return result !== 0 ? result : a?.lastName?.trim()?.localeCompare(b?.lastName?.trim()) || a?.last_name?.trim()?.localeCompare(b?.last_name?.trim());
    });
};

export const onConvertDate = (date?: string | Date, format = 'YYYY-MM-DDTHH:mm:ss') => {
    return moment(date, format);
};

export const capitalizeFirstLetter = (text: string) => {
    if (!text?.trim()) return;

    return text.charAt(0).toLocaleUpperCase() + text.slice(1);
};

export const formatReplyMessage = (text: string, images?: any[], video?: File, file?: File) => {
    const files = [];

    if (images?.length) {
        files.push(`photo${images.length > 1 ? 's' : ''}`);
    }

    if (!isEmpty(video)) {
        files.push('video');
    }

    if (!isEmpty(file)) {
        files.push(file?.fileName);
    }

    return capitalizeFirstLetter(`${text}${files.length > 0 && !!text ? ' and ' : ''}${files.join(', ')}`);
};

export const getMessageById = (id: any, messages: Message[]) => {
    if (!id || isEmpty(messages)) return {};
    const message = messages.filter(message => message?.id === id)[0];
    const index = messages.findIndex(message => message?.id === id);

    return {
        index,
        message
    };
};

export const onGetReplying = (isSameUserReply: boolean, name: string) => {
    return `Replying to ${isSameUserReply ? 'yourself' : name}`;
};

export const onGetMessageImages = (images?: File[]) => {
    return (images || []).map(image => image.url);
};

export const isValidHexColor = (color: string) => {
    if (!color || !color?.trim()) return false;
    const validSixHex = /^#[0-9A-F]{6}$/.test(color.toLowerCase());

    const validThreeHex = /^#([0-9A-F]{3}){1,2}$/i.test(color.toLowerCase());

    return validSixHex || validThreeHex;
};

export const onConvertBase64ToImage = (value: string, mime: string = 'jpeg') => {
    return `data:image/${mime};base64,${value}`;
};

export const onRemoveDataBase64 = (value: string, mime: string = 'jpeg') => {
    if (!isLocalImage(value) || !value) return '';

    return value.replace(`data:image/${mime};base64,`, '');
};

export const onRemoveDataBase64NoMime = (value: string) => {
    if (!isLocalImage(value) || !value) return '';

    return value?.split(',')[1];
};

export const removeEscape = (value: string) => {
    return value.replace(/\\"/g, '"');
};

export const getHTMLHeader = (value: string) => {
    const matched = value.match(/<head[^>]*>[\s\S]*<\/head>/gi);

    if (matched?.length) {
        return removeEscape(matched[0]);
    }

    return '';
};

export const isValidBanners = (colors: string[]) => {
    return !colors?.filter(color => !isValidHexColor(color)).length;
};

export const onGetHTMLWithoutElement = (content: string) => {
    if (!content) return '';

    return decode(getContentsFromHTMLBody(removeAllNewLine(content), true).replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ').replace(/&ndash;/g, '-'));
};

export const onConvertToMD5 = (value: string, hasWebviewKey?: boolean) => {
    return crypto.MD5(`${value}${hasWebviewKey ? webView.key : ''}`);
};

export const onDivideIntoTwoColumns = (arr: any[]) => {
    return arr.reduce(
        (rows: any[], key: any, index: number) => {
            return (
                (index % 2 === 0
                    ? rows.push([key])
                    : rows[rows.length - 1].push(key)) && rows
            );
        },
        []
    );
};

export const sortByGroupName = (data: any[], key?: string) => {
    return data.sort((a, b) => {
        if (key) {
            return a[key]?.localeCompare(b[key]);
        }

        return a?.group_name?.localeCompare(b?.group_name);
    });
};

export const onGetGroupType = (groupType?: string) => {
    if (groupType?.toLowerCase() === 'others') return 'Public';

    return capitalize(groupType || '');
};

export const isValidSocialMedia = (data: any[]) => {
    if (!data || isEmpty(data)) return true;

    return !data?.filter((social: any) => !isValidUrl(social.url) || isEmpty(social?.network?.trim())).length;
};

export const defaultHTMLHeader = '<head><link href="https://fonts.googleapis.com/css?family=Mulish" rel="stylesheet"><style>h1 {font-weight:600;font-size: 32px;color: #282828;}h2{font-weight: 600;font-size: 28px;    color: #AFA47A;    } body {    font-family: \'Mulish\'; font-style: normal; font-weight: 400; font-size: 20px; line-height: 24px; color: #282828;} a:link {color: #AFA47A;} p {margin-<head><link href="https://fonts.googleapis.com/css?family=Mulish" rel="stylesheet"><style>h1 {font-weight:600;font-size: 32px;color: #282828;}h2{font-weight: 600;font-size: 28px;    color: #AFA47A;    } body {    font-family: \'Mulish\'; font-style: normal; font-weight: 400; font-size: 20px; line-height: 24px; color: #282828;} a:link {color: #AFA47A;} p {margin-bottom:20px;}</style></head>';

export const isValidHTMLContent = (content?: string) => {
    return !isEmpty(onGetHTMLWithoutElement((content || '')?.replace(/&nbsp;/g, ''))?.trim());
};

export const removeHTMLElement = (value: string) => {
    if (!value) return '';

    return decode(value.replace(/<[^>]*>?/gm, ''));
};

export const isValidRegionContacts = (data: any[]) => {
    return !data?.filter((region: any) => isEmpty(region.region?.trim()) || isEmpty(region.name?.trim()) || isEmpty(region.contact_details?.trim())).length;
};

export const onFormatChats = (chats: any): Chat[] => {
    if (!chats) return [];

    return Object.keys(chats).map(chat => ({ ...chats[chat], id: chat }));
};

export const onConvertTimeStamp = (date?: number) => {
    if (!date) return null;

    return moment(new Date(date));
};

export const onGetSubTitleChat = (type: ChatType) => {
    switch (type) {
        case 'DIRECT':
            return 'Direct Chat';
        case 'OFFICIAL':
            return 'Group Chat';
        case 'CUSTOM':
            return 'Custom Group Chat';
        default:
            return '';
    }
};

export const onRemoveUndefinedOrNullFromObject = (obj: any) => {
    Object.keys(obj).forEach(key => (obj[key as keyof typeof obj] === undefined || obj[key as keyof typeof obj] === null) && delete obj[key as keyof typeof obj]);

    return obj;
};

export const onFormatFirebaseDate = (date: any) => {
    if (!date?.seconds) return null;

    return moment(new Date(date?.seconds * 1000));
};

export const onGetDataFromFireStore = (data: any[] | any, members?: any) => {
    if (isArray(data)) {
        return data.map(d => {
            const doc = d?.doc || d;

            const metadata = doc?.metadata?._metadata;

            const newData = { ...(doc?.data ? doc?.data() : {}), id: doc?.id, isFromCached: isArray(metadata) && metadata.length > 1 ? metadata[1] : false };

            if (members) {
                const sender = members[newData.author];

                if (sender) {
                    newData.sender = sender;
                }
            }

            return newData;
        });
    }

    const newData = { ...(data?.data() || {}), id: data?.id };

    return newData;
};

export const onSortByDate = (data: any[], field?: string) => {
    const sorted = data?.sort((a, b) => {
        if (field) {
            return new Date(a[field])?.getTime() - new Date(b[field])?.getTime();
        }

        return a?.updated_date - b?.updated_date || a?.date - b?.date;
    });

    return sorted.reverse();
};

export const onEncryptMessage = (text: string, chatId: string) => {
    if (isEmpty(text)) return '';

    return crypto.AES.encrypt(text, `${API_CONFIG.API_ID}-${chatId}`).toString();
};

export const onDecryptMessage = (text: string, chatId?: string) => {
    if (isEmpty(text)) return '';

    if (!chatId) return text;

    try {
        const bytes = crypto.AES.decrypt(text, `${API_CONFIG.API_ID}-${chatId}`);

        return bytes.toString(crypto.enc.Utf8);
    } catch (error) {
        return text;
    }
};

export const isUnmountedError = (error?: any) => {
    return JSON.stringify(error).includes('Unmounted');
};

export const onSortMessages = (messages: Message[]) => {
    const sorted = messages.sort((a, b) => {
        const dateA = a?.date?.seconds ? a?.date?.seconds * 1000 : a?.localTimeStamp;
        const dateB = b?.date?.seconds ? b?.date?.seconds * 1000 : b?.localTimeStamp;

        return dateA - dateB;
    });

    return sorted.reverse();
};

export const onSortChats = (chats: Chat[]) => {
    const sorted = chats.sort((a, b) => {
        const dateA = a?.updated_date?.seconds ? a?.updated_date?.seconds * 1000 : a?.unSentMessage?.localTimeStamp;
        const dateB = b?.updated_date?.seconds ? b?.updated_date?.seconds * 1000 : b?.unSentMessage?.localTimeStamp;

        return dateA - dateB;
    });

    return sorted.reverse();
};

export const onGetInitialsByChat = (name: string, isDirect?: boolean) => {
    if (!isEmpty(name?.trim())) return name;

    if (!isDirect) return onGetInitials(name);

    const splitted = name.split(' ');

    if (splitted.length === 0) return name[0];

    if (splitted.length >= 2) {
        const [first, last] = splitted;

        onGetInitials(first, first, last);
    }

    return '';
};

export const onGenerateCode = (length: number = 6): any => {
    // 2 to escape special chars
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return Array.from({ length }, () => alphabet[Math.floor(Math.random() * alphabet.length)]).join('');
};

export const onGetChatName = (type: ChatType, isUseShortcut?: boolean) => {
    switch (type) {
        case 'CUSTOM':
        case 'OFFICIAL':
            return isUseShortcut ? 'GRP' : 'GROUP';
        default:
            return isUseShortcut ? 'DRT' : 'DIRECT';
    }
};

export const isNotEmptyArrayOfObjects = (data: any) => {
    if (!data) return false;

    return !!Object.keys(data).filter(key => !isEmpty(data[key]))?.length;
};

export const chunk = (array: any[], size: number) => {
    array.reduce((acc, _, i) => {
        if (i % size === 0) acc.push(array.slice(i, i + size));

        return acc;
    }, []);
};

export const hasDuplicates = (array: any[]) => {
    return (new Set(array)).size !== array.length;
};

export const onConvertDateUTC = (date: string | Date) => {
    if (!date) return;

    if (typeof date === 'string') {
        const formattedDate = new Date(date);
        const year = formattedDate?.getUTCFullYear();
        const month = formattedDate?.getUTCMonth() + 1;
        const day = formattedDate.getUTCDate();
        const hours = formattedDate?.getUTCHours();
        const minutes = formattedDate?.getUTCMinutes();

        return moment(`${year}-${month}-${day}T${hours}:${minutes}`, 'YYYY-M-DTH:m').format('YYYY-MM-DDTHH:mm');
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
};

export const getUniqueListByKey = (data: any[], key: string) => {
    return [...new Map(data.map(item => [item[key], item])).values()];
};

export const getQueryParam = (key: string) => {
    const query = new URLSearchParams(window.location.search);

    return query.get(key);
};

export const eventQuestionTypeToDisplay = (eventQuestionType: EventQuestionType) => {
    switch (eventQuestionType) {
        case 'yes/no':
            return 'Yes or No';
        case 'yes/no with text field':
            return 'Yes or No with Others';
        case 'numeric':
            return 'Numeric Dropdown (max 40)';
        case 'text field':
            return 'Paragraph';
        case 'dropdown':
            return 'Dropdown';
    }
};

export const eventQuestionDisplayToEventQuestionType = (eventQuestionDisplay: string) => {
    switch (eventQuestionDisplay) {
        case 'Yes or No':
            return 'yes/no';
        case 'Yes or No with Others':
            return 'yes/no with text field';
        case 'Numeric Dropdown (max 40)':
            return 'numeric';
        case 'Paragraph':
            return 'text field';
        case 'Dropdown':
            return 'dropdown';
    }
};

export const onFormatEvents = (
    events: any,
    isArrayReturn: boolean = true,
    isShowOrganising: boolean = true
) => {
    if (isArray(events)) {
        return events?.map((event) => ({
            ...event,
            types: [onConvertEventTypeToEventPillType(event?.status), ...(event?.cancelled ? ['CANCELLED'] : [])]
        }));
    }

    const { attending, considering, organized_events } = events;

    if (isArrayReturn) {
        return [
            ...(attending || [])?.map((event: any) => ({
                ...event,
                types: ['ATTENDING', ...(event?.cancelled ? ['CANCELLED'] : [])]
            })),
            ...(considering || [])?.map((event: any) => ({
                ...event,
                types: ['CONSIDERING', ...(event?.cancelled ? ['CANCELLED'] : [])]
            })),
            ...(organized_events || [])?.map((event: any) => ({
                ...event,
                types: [
                    ...(isShowOrganising ? ['ORGANISING'] : []),
                    ...[onConvertEventTypeToEventPillType(event?.status)],
                    ...(event?.cancelled ? ['CANCELLED'] : [])
                ]
            }))
        ];
    }

    return {
        attending: [
            ...(attending || [])?.map((event: any) => ({
                ...event,
                types: ['ATTENDING', ...(event?.cancelled ? ['CANCELLED'] : [])]
            }))
        ],
        considering: [
            ...(considering || [])?.map((event: any) => ({
                ...event,
                types: ['CONSIDERING', ...(event?.cancelled ? ['CANCELLED'] : [])]
            }))
        ],
        organized_events: [
            ...(organized_events || [])?.map((event: any) => ({
                ...event,
                types: [
                    ...(isShowOrganising ? ['ORGANISING'] : []),
                    ...[onConvertEventTypeToEventPillType(event?.status)],
                    ...(event?.cancelled ? ['CANCELLED'] : [])
                ]
            }))
        ]
    };
};

const onConvertEventTypeToEventPillType = (type?: 'yes' | 'no' | 'maybe'): EventPillType | undefined => {
    switch (type) {
        case 'yes':
            return 'ATTENDING';
        case 'no':
            return 'DECLINING';
        case 'maybe':
            return 'CONSIDERING';
        case undefined:
            return 'ARE YOU COMING?';
    }
};

export const onConvertEventAnswerToReadable = (answer: 'yes' | 'no' | 'maybe') => {
    switch (answer) {
        case 'yes':
            return 'Yes, I\'m coming';
        case 'no':
            return 'No, I can\'t make it';
        case 'maybe':
            return 'Thinking about it';
    }
};

export const onGetProgressBarPercentageWidth = (total: number = 0, count: number = 0) => {
    if (total === 0) {
        return '0%';
    }

    return `${(count / total) * 100}%`;
};

export const onGetCountryAndRegionFromMapBox = (features: any[]) => {
    if (features?.length) {
        try {
            const context = features[0]?.context;
            const filteredCountry = context?.filter((c: any) => c?.id?.includes('country'));
            const filteredDistrict = context?.filter((c: any) => c?.id?.includes('district'));
            const filteredRegion = context?.filter((c: any) => c?.id?.includes('region'));
            const district = filteredDistrict?.length ? filteredDistrict[0]?.text : features[0]?.text;

            return [filteredCountry[0]?.text, filteredRegion[0]?.text || 'NONE', district || 'NONE'];
        } catch (e) {
            return [undefined, 'NONE', 'NONE'];
        }
    }

    return [undefined, 'NONE'];
};

export const onConvertLocalTimeToUTCTime = (
    dateStr: string,
    format?: string
) => {
    if (!dateStr) return moment().format(format);

    const date = new Date(dateStr);

    const formattedDate = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    )).toISOString();

    if (format) {
        return moment.utc(formattedDate).format(format);
    }

    return formattedDate;
};

export const convertUTCToLocalTime = (dateStr: string, format?: string) => {
    if (!dateStr) return moment().format(format);

    if (format) {
        return moment(moment.utc(dateStr)).local().format(format);
    }

    return moment(moment.utc(dateStr)).local().toDate();
};

export const extractWhat3Words = (url: string) => {
    if (url.startsWith('https://what3words.com/')) {
        return url.replace('https://what3words.com/', '');
    } else if (url.startsWith('https://w3w.co/')) {
        return url.replace('https://w3w.co/', '');
    }

    return '';
};

export const onFormatEventQuestionResults = (results: any[]) => {
    const yes = results?.filter((value: any) => value?.answer?.toLowerCase() === 'yes');
    const no = results?.filter((value: any) => value?.answer?.toLowerCase() === 'no');

    return [
        ...yes,
        ...no,
        ...results?.filter((value: any) => value?.answer?.toLowerCase() !== 'yes' && value?.answer?.toLowerCase() !== 'no')
    ];
};

export const groupBy = (list: any[], fieldName: string) => {
    return list?.reduce((groups, item) => {
        const group = groups[item[fieldName]] || [];

        group.push(item);
        groups[item[fieldName]] = group;

        return groups;
    }, {});
};

export const onConvertJsonToFormData = (data: any) => {
    const formData = new FormData();

    for (const key in data) {
        const newData = data[key as keyof typeof data];

        formData.append(key, typeof newData === 'object' ? JSON.stringify(newData) : newData);
    }

    return formData;
};

export const isValidServiceNumber = (serviceNumber: string) => {
    if (!serviceNumber || typeof serviceNumber !== 'string') return false;

    return /^[a-zA-Z0-9]{1,10}$/.test(serviceNumber);
};

export const onSortNotifications = (notifications: any[]) => {
    return notifications?.sort((a: any, b: any) => new Date(b?.date).getTime() - new Date(a?.date).getTime());
};

export const onHasSameValue = (values: any[], field: string) => {
    const uniqueValues = new Set(values?.map(value => {
        if (typeof value[field] === 'string') {
            return value[field]?.toLowerCase();
        }

        return value[field];
    }));

    return uniqueValues.size < values?.length;
};

export const toTitleCase = (str: string) => {
    if (!str) return '';

    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

export const isPlural = (count: number) => {
    return count === 0 || count > 1;
};

export const onGetCoordinates = (coordinates: any, defaultNumber?: number) => {
    if (!isUndefined(coordinates)) {
        if (isArray(coordinates) && coordinates?.length === 2) {
            return {
                lat: coordinates[1],
                long: coordinates[0]
            };
        }

        if (Object.keys(coordinates).length === 2) {
            return {
                lat: coordinates?.lat,
                long: coordinates?.long
            };
        }
    }

    return {
        lat: defaultNumber,
        long: defaultNumber
    };
};

export const isValidImage = (extension: string) => {
    return /image\/.+/.test(extension);
};

export const onGetAppName = () => {
    const fusiliersKeyword = branding.Fusiliers.keyword;
    const isFusiliers = window.location.href.includes(fusiliersKeyword);
    const environment =
        process.env.REACT_APP_ENV === 'prod'
            ? 'prod'
            : process.env.REACT_APP_ENV === 'staging'
                ? 'staging'
                : 'dev';
    const APP_NAME = isFusiliers ? branding.Fusiliers.siteTitle[environment] : branding['Military App'].siteTitle[environment];

    return APP_NAME;
};

export const onGetDiffDateTimeAndNow = (mil: number, unit: moment.unitOfTime.Base = 'days') => {
    const start = moment(new Date());
    const end = moment(mil);

    return moment.duration(start.diff(end)).get(unit);
};

export const onGetSelectedLocations = (countryOrRegion: string, regions: Region[], isRemoveType?: boolean) => {
    const locations: SelectedLocation = {
        Country: [],
        District: [],
        Region: []
    };

    const selected = countryOrRegion?.split('|');

    selected?.map((s: string) => {
        regions?.map((region: Region) => {
            if (region?.country === s?.replace('Country_', '')) {
                locations.Country = [...new Set([...locations.Country, isRemoveType ? s?.replace('Country_', '') : s])];
            }

            if (region?.district) {
                if (Object.keys(region?.district)?.includes(s?.replace('District_', ''))) {
                    locations.District = [...new Set([...locations.District, isRemoveType ? s?.replace('District_', '') : s])];
                } else {
                    Object.keys(region?.district)?.map(k => {
                        if (region?.district[k]?.includes(s?.replace('District_', ''))) {
                            locations.District = [...new Set([...locations.District, isRemoveType ? s?.replace('District_', '') : s])];
                        }
                    });
                }
            }

            if (region?.regions?.includes(s?.replace('Region_', ''))) {
                locations.Region = locations.Region = [...new Set([...locations.Region, isRemoveType ? s?.replace('Region_', '') : s])];
            }
        });
    });

    return locations;
};

export const removeSelectedLocationsType = (locations: string) => {
    return locations?.replace(/[|]/g, ', ')?.replace(/Country_/g, '')?.replace(/District_/g, '')?.replace(/Region_/g, '');
};

export const onFormatEmbeddedMessageId = (msg?: string, message?: Message) => {
    if (!msg) return '';

    if (!message?.metadata) return msg;

    Object.keys(message?.metadata).map(k => {
        const title = message?.metadata[k]?.title || message?.metadata[k]?.group_name;

        if (msg?.includes(k)) {
            msg = msg.replace(k, title);
        }
    });

    return msg;
};

export const addTargetBlankToHTML = (html: string) => {
    const regex = /<a(?![^>]*\btarget=["']?_blank["']?\b)[^>]*>/g;

    return html.replace(regex, (match) => match.slice(0, -1) + ' target="_blank">');
};

export const onGetNumberOnly = (str: string) => {
    return str?.replace(/\r?\n|\r/g, '')?.replace(/[^0-9]/g, '')?.trim() || '';
};

export const getExtensionFromMIME = (mimeType: string) => {
    const mimeToExtMap = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/webp': 'webp'
    };

    return mimeToExtMap[mimeType as keyof typeof mimeToExtMap] || 'png';
};

export const onGetFirestoreChanges = (changes: any[]) => {
    if (changes?.length === 2) {
        return (changes[1]?.date || changes[1]?.updated_date) && !changes[1]?.isFromCached;
    }

    if (changes?.length === 1) {
        return (changes[0]?.date || changes[0]?.updated_date) && (!changes[0]?.isFromCached || changes[0]?.deleted);
    }

    return true;
};

export const isYesterDay = (date?: moment.Moment) => {
    if (!date) return false;

    const yesterday = moment().subtract(1, 'day');

    return date.isSame(yesterday, 'day');
};

export const getRepliedIndex = (id: any, messages: Message[]) => {
    if (!id || isEmpty(messages)) return -1;

    const index = messages.findIndex(message => message?.id === id);

    return index;
};

export const isCurrentDateIsSameDayFromDate = (date: string) => {
    if (!date) return true;

    const dateToCheck = moment.utc(date);

    return dateToCheck.isSame(moment.utc(), 'day');
};

export const onGetLetters = (str: string) => {
    return str?.trim()?.replace(/[^A-Za-z]/g, '');
};

export const onGetYoutubeIdFromUrl = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

    const match = url?.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    }

    const youtubeShortsLinkPattern = /youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/;

    const matchShort = url.match(youtubeShortsLinkPattern);

    if (matchShort) {
        return matchShort[1];
    }
};

export const onGetPathWithGroupWebsite = (path?: string, groupWebsite?: string) => {
    if (!groupWebsite) return path;

    if (path) {
        if (path[0] !== '/') {
            if (groupWebsite[groupWebsite.length - 1] === '/') {
                return path;
            } else {
                return '/' + path;
            }
        } else {
            if (groupWebsite[groupWebsite.length - 1] === '/') {
                return path?.slice(1);
            } else {
                return path;
            }
        }
    }

    return '';
};

export const onConvertEventAnswerToBody = (answer: 'yes' | 'no' | 'maybe') => {
    switch (answer) {
        case 'yes':
            return 'Yes, I\'m in';
        case 'no':
            return 'No, I can\'t make it';
        case 'maybe':
            return 'Thinking about it';
    }
};

export const millisecondsToTime = (milliseconds?: number) => {
    if (!milliseconds) return '00:00:00';

    let seconds = Math.floor(milliseconds / 1000);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    seconds = seconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const blobToWav = (blob: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const audioContext = new (window.AudioContext || window.AudioContext)();
            const arrayBuffer = event?.target?.result;

            if (!arrayBuffer || typeof arrayBuffer === 'string') return;

            audioContext.decodeAudioData(arrayBuffer, function (audioBuffer) {
                // Create a new WAV Blob
                const wavBlob = encodeAudioBufferToWAV(audioBuffer);

                resolve(wavBlob);
            }, reject);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(blob);
    });
};

export const encodeAudioBufferToWAV = (audioBuffer: any) => {
    const interleaved = interleave(audioBuffer);
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    const view = new DataView(buffer);
    const channels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const sampleCount = audioBuffer.length;

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + interleaved.length * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, channels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * channels, true); // byte rate
    view.setUint16(32, channels * 2, true); // block align
    view.setUint16(34, 16, true); // bits per sample
    writeString(view, 36, 'data');
    view.setUint32(40, interleaved.length * 2, true); // data chunk size
    floatTo16BitPCM(view, 44, interleaved);

    return new Blob([view], { type: 'audio/wav' });
};

export const interleave = (audioBuffer: any) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numberOfChannels;
    const result = new Float32Array(length);
    const channelData = [];

    for (let i = 0; i < numberOfChannels; i++) {
        channelData.push(audioBuffer.getChannelData(i));
    }

    for (let i = 0; i < length; i += numberOfChannels) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
            result[i + channel] = channelData[channel][Math.floor(i / numberOfChannels)];
        }
    }

    return result;
};

export const floatTo16BitPCM = (output: any, offset: any, input: any) => {
    for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));

        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
};

export const writeString = (view: any, offset: any, string: any) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};

export const onHasSameValueArrayOfString = (arr: string[]) => {
    const frequency: any = {};
    const duplicates: any = [];

    arr.forEach(item => {
        frequency[item] = (frequency[item] || 0) + 1;
    });

    for (const key in frequency) {
        if (frequency[key] > 1) {
            duplicates.push(key);
        }
    }

    return !isEmpty(duplicates);
};

export const isValidMinutesInterval = (minutesInterval: number, time?: string) => {
    if (!time) return false;

    const date = moment();
    const momentTime = moment(`${date.format('YYYY-MM-DD')}T${time}`);

    if (!momentTime.isValid()) {
        return false;
    }

    const minutes = momentTime.minutes();

    if (minutes === 0) return true;

    return minutes % minutesInterval === 0;
};

export const formatReadableNumber = (text?: string) => {
    if (!text) return '';

    const cleanNum = text.replace(/,/g, '');

    const [integerPart, decimalPart] = cleanNum.split('.');

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (text.includes('.')) {
        return `${formattedInteger}.${decimalPart.slice(0, 2)}`;
    }

    return formattedInteger;
};

export const onRedirectUrl = (url: string) => {
    window.location.replace(!url?.startsWith('http') ? `https://${url}` : url);
};

export const formatDecimal = (text?: string) => {
    if (!text) return '';

    const cleaned = text.replace(/[^0-9.]/g, '');

    const parts = cleaned.split('.');

    if (parts[1]) {
        return `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    return cleaned;
};
